<template>
  <div class="society">
    <!-- head -->
    <div class="head">
      <Navigator />
      <h3>政府/行业协会</h3>
      <p>Government/Association</p>
    </div>
    <!-- head -->
    <!-- content -->
    <Content :data="content" />
    <!-- content -->
    <!-- Footer -->
    <Footer />
    <!-- Footer -->
  </div>
</template>
<script>
export default {
  components: {
    Content: () => import("./component/Content"),
  },
  data() {
    return {
      content: [
        {
          state: false,
          imgSrc: "https://jsxygkj.oss-cn-hangzhou.aliyuncs.com/official_site/勐腊县易武茶叶协会.mp4",
          // imgSrc: require("@/assets/img/勐腊县易武茶叶协会.mp4"),
          title: "区域茶产业公用品牌管理服务平台",
          text: "区域茶产业公用品牌管理服务平台，为县域茶产地政府和茶行业协会提供服务于本地茶企、茶农的信息平台，以公用品牌发展为基调，将茶园种植、生产、销售纳入监管与服务体系，促进茶农、茶业规范化生产经营，提升区域品牌影响力与竞争力，增进茶产品销量，带动县域茶产业可持续发展。",
          careArr: [
            "平台基于《区域茶产业公用品牌建设要求》标准而建设，此标准由秀圆果公司参与起草",
            "采用云平台架构，为政府、协会、茶企、茶农、消费者提供各类信息云服务",
            "以RFID和二维码的形式提供地理标识防伪标签，连接商品企业和消费得",
            "为政府和协会提供综合可视化大屏，全面展示区域茶产业发展情况",
          ],
          iconArr: [require("@/assets/img/icon16.png"), require("@/assets/img/icon17.png")],
        },
        // {
        //   state: true,
        //   imgSrc: require("@/assets/img/society3.png"),
        //   title: "全国易制毒化学品管理信息系统",
        //   text: "《全国易制毒化学品管理信息系统》由秀圆果公司原班人马在2011年开发建设的公安部金盾二期项目系统，并提供持续的运维服务。《系统》是以易制毒监管领域研究为出发点，立足于监管易制毒化学品行业传统申报、审批形式与网络技术相结合，为各省公安部门提供全面健康有效的监管系统，为各省市企业提供快速便捷的申报管理等全方位的解决方案。实现公安易制毒化学品各级监管部门纵向统筹、其他相关政府部门横向协同、企业公安一站式申请/审批的业务策略，构建了了动态、实时、透明的监管体系。",
        //   careArr: ["公安部国家级信息系统", "覆盖XX省XX城市", "入网企业16万家", "参与国家多项科研课题"],
        //   iconArr: [require("@/assets/img/icon22.png")],
        // },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .society {
    position: relative;
    height: 100%;
    .head {
      height: 8.19rem;
      width: 100%;
      overflow: hidden;
      background: url(../../../assets/img/society-bj.png) no-repeat;
      background-size: 100% 100%;
      h3 {
        font-size: 1.02rem;
        font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.18rem;
        text-shadow: 0px 1px 1px rgba(108, 108, 108, 0.25);
        margin-top: 4.45rem;
        text-align: center;
        // margin-left: 7.1rem;
      }
      p {
        font-size: 0.41rem;
        font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
        font-weight: bold;
        color: #ffffff;
        text-shadow: 0px 1px 1px rgba(108, 108, 108, 0.25);
        // margin-left: 8.38rem;
        text-align: center;
      }
    }
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  .society {
    height: 100%;
    position: relative;
    .head {
      height: 5.48rem;
      width: 19.2rem;
      overflow: hidden;
      background: url(../../../assets/img/society-bj.png) no-repeat;
      background-size: 100% 100%;
      h3 {
        text-align: center;
        height: 1.4rem;
        font-size: 1rem;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #fff;
        line-height: 1.17rem;
        text-shadow: 0rem 0.04rem 0.04rem rgba(108, 108, 108, 0.25);
        margin-top: 2.23rem;
      }
      p {
        height: 0.56rem;
        font-size: 0.4rem;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #ffffff;
        line-height: 0.47rem;
        text-shadow: 0rem 0.04rem 0.04rem rgba(108, 108, 108, 0.25);
        text-align: center;
      }
    }
  }
}
</style>
